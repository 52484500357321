import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tools } from "../Components/Utils/Tools";
import random from "../Components/Utils/random";

function HomeTitles({ init, setInit }) {
  const navigate = useNavigate();
  const [titles, setTitles] = useState({ t: ["Hello.", "I am", "Siddhant"] });
  const [hovering, setHovering] = useState(false);

// ! To Debug timers
  useEffect(() => {
    let originalSetTimeout = setTimeout;
    let originalClearTimeout = clearTimeout;
    window.activeTimers = 0;
    setTimeout = function(func, delay) {
      window.activeTimers++;
        return originalSetTimeout(func, delay);
    };
    clearTimeout = function(timerID) {
      window.activeTimers--;
        originalClearTimeout(timerID);
    };
  }, []);

  useEffect(() => {
    var t1, t2, t3, t4, int1, int2;
    function shuffle() {
      t1 = setTimeout(() => {
        int1 = setInterval(() => {
          let x = Math.floor(Math.random() * random.randomstr.length - 100 + 1);
          setTitles({
            t: [
              random.randomstr.slice(x, x + 4),
              random.randomstr.slice(x + 10, x + 14),
              random.randomstr.slice(x + 20, x + 26),
            ],
          });
        }, 100);

        t2 = setTimeout(() => {
          clearInterval(int1);
          setTitles({ t: ["Hello.", "I am", "Siddhant"] });
        }, 1000);
      }, 0);

      t3 = setTimeout(() => {
        int2 = setInterval(() => {
          let x = Math.floor(Math.random() * random.randomstr.length - 100 + 1);
          setTitles({
            t: [
              random.randomstr.slice(x, x + 4),
              random.randomstr.slice(x + 10, x + 13),
              random.randomstr.slice(x + 20, x + 27),
            ],
          });
        }, 100);

        t4 = setTimeout(() => {
          clearInterval(int2);
          setTitles({ t: ["About", "Works", "Contact"] });
        }, 1000);
      }, 4000);
    }

    if (!hovering) {
      shuffle();
      var titlesInterval = setInterval(shuffle, 8000);
    }

    return () => {
      clearTimeout(t1);
      clearTimeout(t2);
      clearTimeout(t3);
      clearTimeout(t4);
      clearInterval(int1);
      clearInterval(int2);
      clearInterval(titlesInterval);
      console.log("cleared");
    };
  }, [hovering]);

  return (
    <div>
      <div className="Home-titles-wrapper">
        <div className="techyTransition">{"<h1>"}</div>
        <div
          onMouseEnter={() => {
            Tools.onHoverTitles(setTitles, "enter");
            setHovering(true);
          }}
          onMouseLeave={() => {
            Tools.onHoverTitles(setTitles, "leave");
            setHovering(false);
          }}
          onClick={() => navigate("/about")}
          className="Home-titles"
        >
          <div className="Home-titles-back"></div>
          <span
            className={`Home-titles-word delay03 ${
              init ? "Home-init-on" : "Home-init-off"
            }`}
          >
            {titles.t[0]}
          </span>
        </div>
        <div
          onMouseEnter={() => {
            Tools.onHoverTitles(setTitles, "enter");
            setHovering(true);
          }}
          onMouseLeave={() => {
            Tools.onHoverTitles(setTitles, "leave");
            setHovering(false);
          }}
          onClick={() => navigate("/work")}
          className="Home-titles red"
        >
          <div className="Home-titles-back"></div>
          <span className="Home-titles-word delay06">{titles.t[1]}</span>
        </div>
        <div
          onMouseEnter={() => {
            Tools.onHoverTitles(setTitles, "enter");
            setHovering(true);
          }}
          onMouseLeave={() => {
            Tools.onHoverTitles(setTitles, "leave");
            setHovering(false);
          }}
          onClick={() => navigate("/contact")}
          className="Home-titles red"
        >
          <div className="Home-titles-back"></div>
          <span className="Home-titles-word delay09">{titles.t[2]}</span>
        </div>
        <div className="techyTransition">{"</h1>\n"}</div>
      </div>
    </div>
  );
}

export default HomeTitles;

import React, { useState, useEffect } from "react";
import random from "../Components/Utils/random";
import "./HomeAvatar.css";

function Avatar() {
  const [transitionText, setTransitionText] = useState("");

  useEffect(() => {
    var text = random.htmlCode;

    /*
    1. no of char shift * no of times 
    2. no of char * no of times = total no of char -> 1000 * 6 = 6000
    */

    const noOfcharShift = 100;
    const noOfChar = 1000;
    const noOfTimes = 9;

    const animationTimeOffset = 100;
    const animationTimePeriod = 150;
    const animationTimes = 8;
    // const htmlSetTime = 2600; //1400 + 1200
    const htmlSetTime = animationTimeOffset + animationTimePeriod * animationTimes;

    for (let t = 0; t <= animationTimes; t++) {
      setTimeout(() => {
        var myarr = "";
        var str = random.randomstr.slice(
          t * noOfcharShift,
          t * noOfcharShift + noOfChar
        );
        for (let i = 0; i < noOfTimes; i++) {
          myarr = myarr + str;
        }
        setTransitionText(myarr);
      }, animationTimePeriod * t + animationTimeOffset);
    }

    setTimeout(() => {
      setTransitionText(text);
    }, htmlSetTime);
  }, []);

  return (
    <div className="Avatar">
      <div className="Avatar-p">{transitionText}</div>
    </div>
  );
}

export default Avatar;

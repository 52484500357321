import React, { useState } from "react";
import db from "../../Auth/firebase";
import { addDoc, collection } from "firebase/firestore";
import { useRef } from "react";

function ContactForm() {
  const [payload, setPayload] = useState({});
  const [rocket, setRocket] = useState(false);
  const form = useRef(null);

  function changingPayload() {
    setPayload(() => ({
      name: form.current[0].value,
      mail: form.current[1].value,
      message: form.current[2].value,
    }));
    console.log("Payload", payload);
  }

  function handleSubmit(e) {

    

    setRocket(true);
    setTimeout(() => {
      setRocket(false);
    }, 2000);
    e.preventDefault();
    (async () => {
      const req = await addDoc(collection(db, "Messages"), payload);
      console.log("Message added: ", req.id);
    })().catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      alert(errorCode + errorMessage);
    });
  }
  return (
    <form className="Contact-form" ref={form}>
      <div className="Contact-form-input">
        <div htmlFor="fname">Your Name</div>
        <input
          type="text"
          onChange={changingPayload}
          placeholder="Enter your name"
        />
      </div>
      <div className="Contact-form-input">
        <div htmlFor="mail">Email Address</div>
        <input
          type="email"
          onChange={changingPayload}
          placeholder="Enter your email address"
        />
      </div>
      <div className="Contact-form-input Contact-form-input-big">
        <div htmlFor="msg">Your Message</div>
        <textarea
          rows="2"
          type="text"
          onChange={changingPayload}
          placeholder="Hi, we need a front-end dev for our website at Company X. How
              soon can you hop on to discuss this?"
        />
      </div>
      <br />

      <button className="Contact-form-btn" value="Shoot" onClick={handleSubmit}>
        Fire!
        <img src={require("../../Media/red-arrow.png")} alt="" />
      </button>

      {rocket && (
        <div className="Rocket">
          <img src={require("../../Media/rocket/rocket2.gif")} alt="" />
        </div>
      )}
    </form>
  );
}

export default ContactForm;
